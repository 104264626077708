import * as React from 'react';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from '../components/Layout';
import componentPicker from '../util/componentPicker';

const IndexPage = ({ data }) => {
    const page = data.wpPage;

    return (
        <Layout>
            <Seo post={data.wpPage} />
            {page.blocks.map((block, i) => (
                <React.Fragment key={i}>
                    {componentPicker(block)}
                </React.Fragment>
            ))}
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    query {
        wpPage(isFrontPage: { eq: true }) {
            id
            title
            seo {
                title
                metaDesc
                focuskw
                metaKeywords
                metaRobotsNoindex
                metaRobotsNofollow
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    srcSet
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
                twitterTitle
                twitterDescription
                twitterImage {
                    altText
                    sourceUrl
                    srcSet
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
                canonical
                cornerstone
                schema {
                    articleType
                    pageType
                    raw
                }
            }
            blocks {
                ...fragmentBlocks
            }
        }
    }
`;
